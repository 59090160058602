.handle-users {
  p {
    color: var(--text-color);
  }

  .title.section {
    padding-top: 0 !important;
  }

  .section {
    padding-top: 20px;
    padding-bottom: 25px;
    border-bottom: 1px solid var(--border-color);

    p.light-text {
      max-width: 24rem;
      opacity: 0.5;
    }

    .section-wrapper {
      display: flex;
      justify-content: space-between;
      width: 70%;

      @media screen and (max-width: 1000px) {
        width: 100%;
      }

      .left {
        display: flex;
        flex-direction: column;
        gap: 8px 0px;
      }

      .right {
        width: 50%;

        .input {
          display: flex;
          border: 1px solid var(--border-color);
          padding: 11px;
          border-radius: 5px;
          width: 100%;
          min-width: 316px;

          @media screen and (max-width: 1200px) {
            min-width: 100% !important;
          }

          .input-icon {
            margin-right: 13px;

            svg {
              color: black;
              opacity: 0.24;
              font-size: 16px;
            }
          }

          input {
            border: none;
            width: 100%;
          }

          input:focus {
            outline: none;
          }
        }
      }
    }
  }

  #full_name {
    margin-bottom: 2rem;
  }

  .title {
    h3 {
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 16px;
    }
  }

  .buttons {
    margin-top: 1.5rem;
    display: flex;
    gap: 0px 22px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 28px;
      border: none;
      border-radius: 5px;
      color: white;
    }

    #save-btn {
      background-color: var(--green);
    }

    #delete-btn {
      background-color: var(--red);
    }
  }

  #subusers-table {
    margin-top: 8rem;
  }

  .create-btn {
    background-color: var(--gray); // Default color
    color: white;
    border-radius: 5px;
    padding: 8px 12px;
    font-size: 14px;
    cursor: not-allowed; // Default state is disabled

    &.enabled {
      background-color: var(--green); // Use the same green as the save button
      cursor: pointer;
    }

    &.disabled {
      opacity: 0.5; // Gray out when disabled
    }
  }

}