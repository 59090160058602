.users_search-bar {
  input {
    width: 70%;
    border: 1px solid var(--border-color);
    padding: 0.75rem 1.16rem;
    border-radius: 5px;
  }

  #user-table {
    margin-top: 2rem;
    border: 1px solid black;
  }

  button {
    background: var(--green);
  }
}

.enable-client {
  svg {
    color: black;
    opacity: 0.75;
  }
}

.loading-box {
  width: 1093px;
  max-width: 100%;

  height: 560px;
  max-height: 100%;

  background-image: linear-gradient(to right, var(--global-green), #d4ffec, white);
  animation: colorchange 10s linear infinite;

  @keyframes colorchange {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
}

.data-table {
  .search-input {
    display: flex;
    align-items: center;
    width: 80%;
    margin-right: auto;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    position: relative;

    .icon {
      position: absolute;
      right: 1rem;
    }

    input:focus {
      border: none;
      outline: none;
    }

    input {
      border: none;
      padding: 8px 12px;
      width: 95%;
      border-radius: 5px;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    gap: 0px 10px;

    svg {
      font-size: 20px;
    }
  }

  .extra-items {
    color: var(--green);
    text-decoration: underline;
    font-size: 0.9rem;
    cursor: pointer;

    .expanded {
      display: block;
    }

    .collapsed {
      display: none;
    }
  }
}

#clients {
  table {
    th,
    td {
      font-weight: bold;
      font-size: 1rem;
    }

    th:nth-child(4),
    tr td:nth-child(4) {
      text-align: center;
    }

    th {
      min-width: none;

      .actions {
        width: 100px;
        margin: 0 auto;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .header {
    flex-wrap: wrap;

    .search-input {
      width: 99%;
      margin: auto;
      margin-bottom: 12px;
    }

    .filters {
      gap: 0px 12px;

      select {
        margin: 0;
      }
    }
  }
}