#main-container {

  /**** Tooltip Styles ****/
  .tooltip-container {
    display: inline-block;
    position: relative;
    margin-left: 8px;
    cursor: pointer;

    .tooltip-icon {
      color: #007bff;
      /* Blue icon color */
      font-size: 14px;
      cursor: pointer;
    }

    .tooltip {
      visibility: hidden;
      /* Hidden by default */
      opacity: 0;
      /* Fully transparent */
      position: absolute;
      top: 50%;
      /* Adjust position */
      left: 120%;
      /* Adjust alignment */
      transform: translateY(-50%);
      background-color: #333;
      /* Tooltip background */
      color: #fff;
      /* Tooltip text color */
      padding: 8px;
      border-radius: 4px;
      font-size: 12px;
      white-space: nowrap;
      z-index: 10;
      transition: opacity 0.3s ease-in-out;
    }

    &:hover .tooltip {
      visibility: visible;
      /* Make tooltip visible */
      opacity: 1;
      /* Fully opaque */
    }
  }

  #containersLessThan1280px {
    display: none;
  }

  @media screen and (max-width: 1280px) {
    #containersLessThan1280px {
      display: flex !important;
    }

    #containersOver1280px {
      display: none !important;
    }
  }

  .container-extensor {
    color: var(--green);
    font-size: 10px;
    text-decoration: underline;
    margin-left: 5px;
  }

  .data-table {
    min-height: 400px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .filters {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;

        .css-1xlimdq-control {
          border-radius: 5px;
        }

        @media screen and (max-width: 1000px) {
          justify-content: flex-start;
          flex-wrap: wrap;
          grid-gap: 1rem;

          .css-b62m3t-container {
            flex-grow: 1;
          }

          .css-1xlimdq-control {
            width: auto;
          }
        }
      }

      @media screen and (max-width: 1000px) {
        grid-gap: 0;
      }
    }

    .table-parts {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .pagination {
      margin-top: 12px;
      width: fit-content;
      margin-left: auto;

      .text {
        color: #707070;
        font-size: 12px;
        margin: 0px 8px;
      }

      button {
        background-color: transparent !important;
        border: none;
        color: var(--green);
        font-size: 12px;
      }
    }
  }

  .shipments {

    td,
    th {
      width: 100px;
    }

    td.containers {
      width: 150px;
    }

    /* Tooltip functionality */
    .file_no {
      position: relative;

      .tooltip-container {
        display: inline-block;
        position: relative;
        margin-left: 8px;
        cursor: pointer;

        .tooltip-icon {
          color: #007bff;
          font-size: 14px;
        }

        .tooltip {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          top: 50%;
          left: 120%;
          transform: translateY(-50%);
          background-color: #333;
          color: #fff;
          padding: 8px;
          border-radius: 4px;
          font-size: 12px;
          white-space: nowrap;
          z-index: 10;
          transition: opacity 0.3s ease-in-out;
        }

        &:hover .tooltip {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  th.status-th {
    width: 14rem;
  }

  table {
    width: 100%;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    text-align: left;
    border-collapse: collapse;

    .right {
      p {
        width: fit-content;
        margin-left: auto;
      }
    }

    thead {
      th p {
        font-size: 14px;
        font-weight: 600;
      }

      th.right {
        text-align: left;
      }

      th {
        padding: 16px 6px;
        font-size: 14px;
        font-weight: 600;

        svg {
          position: relative;
          top: 5px;
          left: 3px;
          font-size: 17px;
        }
      }
    }

    tbody {
      font-size: 12px;
      font-weight: 500;

      tr {
        .file_no {
          text-align: left;
          font-weight: bolder;
        }

        .fda_status {
          .fda-fail {
            color: var(--red);
          }

          .fda-success {
            color: var(--green);
          }
        }

        &:hover {
          background-color: #23b57420;
        }

        &.checked td {
          background-color: #8cdab7;
        }

        &.highlighted {
          background-color: yellow;
        }

        td {
          border-top: 1px solid var(--border-color);
          padding: 16px 6px;
          word-break: break-word;
          line-height: 1.4;

          &.highlighted span,
          &.highlighted p,
          &.highlighted a {
            background-color: #fff8b3;
            padding: 0 2px;
            border-radius: 2px;
            display: inline-block;
          }

          &.highlighted a span {
            background-color: inherit;
          }

          .extra-containers {
            color: var(--green);
            text-decoration: underline;
            font-size: 0.9rem;
            cursor: pointer;
          }
        }

        &:hover td.highlighted span,
        &:hover td.highlighted p,
        &:hover td.highlighted a {
          background-color: #fff8b3 !important;
        }
      }
    }

    @media screen and (max-width: 1000px) {
      thead th {
        white-space: nowrap;
        padding: 1rem 4rem 1rem 6px;
      }

      td.status {
        white-space: nowrap;
      }
    }
  }
}

.loading-table {
  background: linear-gradient(-45deg, #efefef, #ffffff, #efefef, #ffffff);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  border-radius: 5px;
  width: 100%;
  min-height: 500px;
}

.select_shipment {
  width: 10px !important;
  padding: 0 !important;
}

td.fda_status {
  .pass {
    color: green;
  }

  .fail {
    color: red;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}