#create-user {
  padding-top: 55px;

  .error-message,
  .success-message {
    margin-top: 1rem;
  }

  .body {
    section {
      margin-bottom: 22px;

      .container {
        width: 75%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right {
          width: 100%;
          max-width: 316px;
          gap: 0 11px;
          padding: 14px 12px;
          border: 1px solid #E9E9E9;
          border-radius: 5px;

          .icon {
            height: 16px;

            svg {
              position: relative;
              top: -3px;
              font-size: 21px;
              color: black;
              opacity: .25;
            }
          }

          input {
            border: none;
            width: 100%;
          }

          input:focus,
          select:focus {
            outline: none;
          }
        }
      }
    }

    section._4 {
      .right {
        position: relative;

        .icon {
          cursor: pointer;
        }
      }
    }

    section._5 {
      .custom-select {
        width: 100%;
        max-width: 316px;
      }
    }

    section._6 {
      .container {
        width: 75%; // Ensure consistency with other sections
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          p._2 {
            font-size: 14px;
            color: #303030; // Matches the password description text
          }
        }

        .right {
          width: 100%; // Consistent with other sections
          max-width: 316px; // Matches the width of input fields
          display: flex;
          flex-direction: column;
          gap: 10px;

          .toggle-row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
              margin: 0;
              font-size: 14px;
              color: #333;
            }

            .toggle-switch {
              position: relative;
              display: inline-block;
              width: 40px;
              height: 20px;

              input {
                opacity: 0;
                width: 0;
                height: 0;
              }

              .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #e9e9e9;
                transition: 0.4s;
                border-radius: 10px;
              }

              input:checked+.slider {
                background-color: #4caf50;
              }

              .slider:before {
                position: absolute;
                content: "";
                height: 14px;
                width: 14px;
                left: 3px;
                bottom: 3px;
                background-color: white;
                transition: 0.4s;
                border-radius: 50%;
              }

              input:checked+.slider:before {
                transform: translateX(20px);
              }
            }
          }
        }
      }
    }

    section:not(._1) {
      padding-top: 29px;
      border-top: 1px solid #E9E9E9;

      p._1 {
        margin-bottom: 8px;
      }

      p._2 {
        max-width: 237px;
      }
    }

    section._1 {
      margin-bottom: 12px;

      p._1 {
        margin-bottom: 12px;
      }
    }

    select {
      padding: 0;
      width: 100%;
      height: 100%;
      border: none;
    }

    .buttons {
      margin-top: 44px;
      gap: 0 22px;

      .button {
        font-size: 12px !important;
        width: 100%;
        max-width: 90px;
        padding: 7px 0;
        border-radius: 5px;
      }
    }
  }
}